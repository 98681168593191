

import logo from '../Inverse-Shield.svg';

export default function Header() {
	return (
		<div className='w-full flex flex-col bg-banner-navy items-center'>
			<div className='w-full xl:w-1/2'>
				<a href='https://pimpamassc.eq.edu.au/' className='flex flex-row'>
					<img className='p-4' width={128} src={logo} alt='Pimpama State Secondary College logo'></img>
					<div className='flex flex-col justify-center'>
						<h1 className='xl:text-2xl font-medium text-white'>Pimpama State Secondary College</h1>
						<h1 className='xl:text-l text-white'>Together we succeed</h1>
					</div>
				</a>

			</div>



		</div>


	)
}