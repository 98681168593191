import { useEffect, useState } from "react";

import { SERVER_URL, lastFileState, newUploadedDataState, studentDataState } from "../data/data";
import { useRecoilState, useRecoilValue } from "recoil";
import { upload } from "@testing-library/user-event/dist/upload";

class SavedData {
    constructor(key, values) {
        this.key = key;
        this.values = values;
    }
}


export default function LocalFileHistory(props) {

    const [uploadedItems, setUploadedItems] = useState()

    var lastFile = useRecoilValue(lastFileState)

    var studentData = useRecoilValue(studentDataState);


    useEffect(() => {

        SetLocalStorage(lastFile)



    }, [lastFile])


    const videoIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
        </svg>

    );
    const documentIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
        </svg>

    );
    const audioIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z" />
        </svg>

    );
    const photoIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
        </svg>

    );
    const archiveIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>

    )



    function ExtIcon(item) {



        var iconType = 'image';

        var splitString = item.split(".");
        var fileExtension = splitString[splitString.length - 1]

        console.log(fileExtension)

        switch (fileExtension) {

            case 'mp4':
                return (
                    <div className="text-purple-500">
                        {videoIcon}
                    </div>
                )
                break;
            case 'mov':
                return (
                    <div className="text-purple-500">
                        {videoIcon}
                    </div>
                )
                break;
            case 'pdf':
                return (
                    <div className="text-red-500">
                        {documentIcon}
                    </div>
                )
                break;
            case 'doc':
                return (
                    <div className="text-blue-500">
                        {documentIcon}
                    </div>
                )
                break;
            case 'docx':
                return (
                    <div className="text-blue-500">
                        {documentIcon}
                    </div>
                )
                break;
            case 'png':
                return (
                    <div className="text-green-500">
                        {photoIcon}
                    </div>
                )
                break;
            case 'jpeg':
                return (
                    <div className="text-green-500">
                        {photoIcon}
                    </div>
                )
                break;
            case 'jpg':
                return (
                    <div className="text-green-500">
                        {photoIcon}
                    </div>
                )
                break;
            case 'avif':
                return (
                    <div className="text-green-500">
                        {photoIcon}
                    </div>
                )
                break;
            case 'heic':
                return (
                    <div className="text-green-500">
                        {photoIcon}
                    </div>
                )
                break;
            case 'zip':
                return (
                    <div className="text-yellow-500">
                        {archiveIcon}
                    </div>
                )
                break;



        }

    }


    async function GetLocalStorage() {

        const saved = localStorage.getItem()



    }

    async function SetLocalStorage(data) {

        var masterMap = new Map();


        var localData = localStorage.getItem('data')

        if (localData == null) {
            console.log('localData false')

            if (lastFile.length > 0) {

                console.log('localData false lastFile true')
                console.log(lastFile)

                var saveMap = new Map();
                var tempArr = []

                for (var i = 0; i < lastFile.length; i++) {

                    tempArr.push(lastFile[i])

                }

                saveMap.set(studentData[0], tempArr)


                localStorage.setItem('data', JSON.stringify(Object.fromEntries(saveMap)))



                setUploadedItems(saveMap)





            } else {
                console.log('localData false lastFile false')
            }

        } else {
            console.log('localData true')

            if (lastFile.length > 0) {
                console.log('localData true lastFile true')

                const savedData = new Map(Object.entries(JSON.parse(localStorage.getItem('data'))))

                var course = savedData.get(studentData[0])

                if (course == null) {
                    console.log('Not previously uploaded, creating new')

                    var tempArr = []

                    for (var i = 0; i < lastFile.length; i++) {
                        tempArr.push(lastFile[i])
                    }

                    savedData.set(studentData[0], tempArr)

                } else {

                    var tempArr = Array.from(savedData.get(studentData[0]))

                    console.log("Temp: " + tempArr)
                    console.log("Last: " + lastFile[0])

                    for (var i = 0; i < lastFile.length; i++) {




                        if (tempArr.includes(lastFile[i]) == false) {
                            tempArr.push(lastFile[i])
                        } else {
                            console.log('repeat')
                        }
                    }

                    savedData.set(studentData[0], tempArr)

                }

                localStorage.setItem('data', JSON.stringify(Object.fromEntries(savedData)))


                setUploadedItems(savedData)

            } else {
                console.log('localData true lastFile false')

                const savedData = new Map(Object.entries(JSON.parse(localStorage.getItem('data'))))

                setUploadedItems(savedData)
            }


        }



        // if (lastFile.length > 0)
        //     console.log("Last: " + lastFile.length)

        // if (localStorage.getItem('data') != null) {

        //     console.log('has data')

        //     if (masterMap.get(studentData[0]) == null) {
        //         console.log('key unavailable')
        //     } else {


        //         // masterMap.set('APA Drama', ['Lorem_ipsum copy.pdf', 'Screenshot_20230629-112756 2.heic', 'Lorem_ipsum copy 5.pdf'])
        //         // masterMap.set('APA Music', ['Screenshot_20230629-112756 2.heic', 'file9.mp4', 'file5.mov'])

        //         // localStorage.setItem('data', JSON.stringify(Object.fromEntries(masterMap)))

        //         // const saved = new Map(Object.entries(JSON.parse(localStorage.getItem('data'))))


        //         // setUploadedItems(saved)


        //         if (lastFile.length > 0) {


        //             var tempArr = masterMap.get(studentData[0])

        //             tempArr.push(lastFile)

        //             masterMap.set(studentData[0], tempArr)

        //             localStorage.setItem('data', JSON.stringify(Object.fromEntries(masterMap)))


        //         }



        //     }



        // } else {

        //     if (studentData[0] != 'Select Course' && lastFile.length > 0) {


        //         masterMap.set(studentData[0], lastFile)

        //         localStorage.setItem('data', JSON.stringify(Object.fromEntries(masterMap)))


        //     }

        // }




        // masterMap.set('APA Drama', ['Lorem_ipsum copy.pdf', 'Screenshot_20230629-112756 2.heic', 'Lorem_ipsum copy 5.pdf'])
        // masterMap.set('APA Music', ['Screenshot_20230629-112756 2.heic', 'file9.mp4', 'file5.mov'])



        // const saved = new Map(Object.entries(JSON.parse(localStorage.getItem('data'))))

        // console.log(saved.get('APA Drama'))

        // console.log(studentData[0] + lastFile)


        // setUploadedItems(saved)











    }

    function FileHistoryList(key) {

        if (uploadedItems != null) {

            // console.log(Array.from(uploadedItems.get(key)))

            var arr = Array.from(uploadedItems.get(key))

            // console.log(arr[0])


            return Array.from(uploadedItems.get(key)).map((item) => {
                if(item != null) {
                    return (
                        <div className="flex flex-row justify-between" key={item}>
                            <div className="flex flex-row items-center p-2" >
                                <div className="w-6 h-6 mr-3">
                                    {ExtIcon(item)}
                                </div>
                                <div className="text-center">
                                    {item}
                                </div>
                            </div>
                        </div>
                    )

                }
                
            })

        }




    }


    function FileHistoryHeader() {

        if (uploadedItems != null) {

            var arr = Array.from(uploadedItems.keys())

            // console.log("Map keys are: " + arr)

            return arr.map((item) => {
                return (

                    <div>
                        <div className="font-semibold p-2">
                            {item}
                        </div>

                        <div className="flex flex-col">
                            {FileHistoryList(item)}
                        </div>

                    </div>

                )


            })


        }






    }







    function ListMap(data) {
        if (data != null) {
            return data.map((item) => {
                return (
                    <div className="flex flex-row justify-between" key={item}>
                        <div className="flex flex-row items-center p-2" >
                            <div className="w-6 h-6 mr-3">
                                {ExtIcon(item)}
                            </div>
                            <div className="text-center">
                                {item}
                            </div>
                        </div>
                    </div>
                );
            })
        } else {
            return (<div></div>)
        }


    }



    return <div className="">
        <div className="flex-col bg-slate-100 rounded-xl">


            {uploadedItems != null ?
                <div>
                    <div className="p-2 text-center font-semibold">
                        Uploaded items
                    </div>
                    {FileHistoryHeader()}
                </div>
                :
                <div>
                </div>
            }










        </div>



    </div>
}