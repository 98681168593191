
import './App.css';

import Header from './components/Header'
import Footer from './components/Footer'

import { useEffect, useState } from 'react';

import { FileUploader } from "react-drag-drop-files";
import DragDrop from './components/DragDrop';

import { newUploadedDataState, studentDataState } from './data/data'


import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import LocalFileHistory from './components/LocalFileHistory';


const uploadedDataState = atom({
  key: 'UploadedData',
  default: []
});




function App() {



  const [isLoading, setLoading] = useState(true);
  const [studentData, setStudentData] = useRecoilState(studentDataState)
  var studentDataTemp = ['Select Course', '', '', '', '']




  async function getURLParams() {

    // console.log(window.location.href)

    var urlExtension;

    // console.log(/([^\/]+$)/.exec(window.location.href))

    if (/([^\/]+$)/.exec(window.location.href) == null) {
      console.log("No extensions")
    } else {

      urlExtension = /([^\/]+$)/.exec(window.location.href)[1]

      const searchParams = new URLSearchParams(urlExtension);

      var list = []

      list.push(searchParams.get("code"))
      list.push(searchParams.get("firstName"))
      list.push(searchParams.get("lastName"))
      list.push(searchParams.get("email"))
      list.push("PREFILL")

      setStudentData(list);

      // setStudentData({list}, () => {

      // });

      // console.log(studentData)
    }





  }







  useEffect(() => {

    getURLParams();

  }, [isLoading])




  function userSelectInput(fieldType, studentDataIndex) {

    const onChangeHandler = (event, index) => {

      console.log(event.target.value)

      var studentDataScratch = [...studentData]

      studentDataScratch[index] = event.target.value
      console.log(studentDataScratch)
      setStudentData(studentDataScratch)
      console.log("Scratch " + studentDataScratch)
      console.log("Data " + studentData)

    };

    return (
      <div className='p-2'>
        <div className='flex flex-row min-w-full justify-start'>
          <div className='p-1 flex h-12 w-36 text-center justify-center items-center rounded-l-xl bg-slate-100'>
            {fieldType}
          </div>
          {studentData[4] == "PREFILL" ?
            <input type='text' className='flex p-1 rounded-r-xl bg-slate-200' value={studentData[studentDataIndex]} disabled /> :
            <select className='flex w-full p-1 rounded-r-xl bg-slate-50' onChange={(e) => onChangeHandler(e, studentDataIndex)}>
              <option value="Select Course">Select Course</option>
              <option value="APA Drama">APA Drama</option>
              <option value="APA Dance">APA Dance</option>
              <option value="APA Music">APA Music</option>
              <option value="CoDesign">CoDesign</option>
              <option value="EAD">EAD</option>
              <option value="English Humanities Extension">English Humanities Extension</option>
              <option value="Enterprise">Enterprise</option>
              <option value="STEMd">STEMd</option>
              <option value="Scholarships">Scholarships</option>
            </select>
          }


        </div>
      </div>
    )
  }


  function userTextInput(fieldType, studentDataIndex) {

    const onChangeHandler = (event, index) => {

      console.log(event.target.value)

      var studentDataScratch = [...studentData]

      studentDataScratch[index] = event.target.value
      console.log(studentDataScratch)
      setStudentData(studentDataScratch)
      console.log("Scratch " + studentDataScratch)
      console.log("Data " + studentData)

    };


    return (
      <div className='p-2'>
        <div className='flex flex-1 flex-row min-w-full'>
          <div className='p-1 flex h-12 w-36 text-center justify-center items-center rounded-l-xl bg-slate-100'>
            {fieldType}
          </div>
          {studentData[4] == "PREFILL" ?
            <input type='text' className='p-1 rounded-r-xl bg-slate-200' value={studentData[studentDataIndex]} disabled /> :
            <input type='text' className='flex w-full p-1 rounded-r-xl bg-slate-50' onChange={(e) => onChangeHandler(e, studentDataIndex)} />
          }


        </div>
      </div>
    )
  }





  return (
    <div className='flex flex-col'>

      <Header />
      <div className='bg-college-navy mb-auto flex w-full justify-center'>
        <div className='w-2/3 flex flex-col'>

          <div className='justify-center text-white font-semibold text-4xl text-center m-2 p-2'>

            Submission Portal

          </div>

        </div>


      </div>
      <div className='bg-college-navy mb-auto flex w-full h-screen justify-center'>
        <div className='w-full xl:w-1/2 md:w-4/5 flex flex-col space-y-5'>

          <div className='flex flex-col  justify-between space-y-5'>


            <div className='flex grid grid-cols-1 md:grid-cols-2'>
            {userSelectInput('Code', 0)}
              {userTextInput('Email', 3)}
              {userTextInput('First Name', 1)}
              {userTextInput('Last Name', 2)}
            </div>










          </div>


          {studentData[0] == 'Select Course' ? <div></div> : <DragDrop data={studentData} /> }



          
          <LocalFileHistory/>



        </div>


      </div>
      <Footer />

    </div>
  );
}

export default App;
