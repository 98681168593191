import { atom } from 'recoil';

export const newUploadedDataState = atom({
    key: 'NewUploadedData',
    default: false
});


export const studentDataState = atom({
    key: 'StudentData',
    default: ['Select Course', '', '', '', '']
});

export const lastFileState = atom ({
    key: 'LastFile',
    default: []
})

// export const MINIO_URL = "http://172.16.0.53:32771";
export const SERVER_URL = "https://upload-api.pssc.tech";
// export const SERVER_URL = "http://100.72.32.53:3001";
// export const SERVER_URL = "http://localhost:3001";