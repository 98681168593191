
import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PulseLoader from "react-spinners/PulseLoader";


// var Minio = require('minio')

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { SERVER_URL, lastFileState, newUploadedDataState } from '../data/data'

function DragDrop(props) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [lastFile, setLastFile] = useRecoilState(lastFileState)


  // var uploading = false;

  const uploadIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
    </svg>

  );



  async function Upload(files) {

    setUploading(true)

    console.log(files)


    const formData = new FormData();

    formData.append("code", props.data[0])
    formData.append("firstName", props.data[1])
    formData.append("lastName", props.data[2])
    formData.append("email", props.data[3])

    for (var x = 0; x < files.length; x++) {

      formData.append('files', files[x])

    }

    formData.append("files", files)

    let headers = new Headers()

    headers.append('Access-Control-Allow-Origin', '*')


    try {



      const response = await fetch(SERVER_URL + "/upload/", {
        method: "POST",
        body: formData,
        mode: 'cors',
        headers: headers
      });
      const result = await response;
      console.log("Success:", result);
    } catch (error) {
      console.error("Error:", error);
    }

    // setLastFile(files)

    setUploading(false)

    var uploaded = []

    for (var i = 0; i < files.length; i++) {

      uploaded.push(files[i].name)

    }

    if (uploaded.length > 0) {

      setLastFile(uploaded)


    }

  }




  const handleChange = (file) => {
    setFile(file);
    console.log(file)
    Upload(file);
  };

  const onDrop = (file) => {
    console.log(file)
  }

  return (
    <div className="flex flex-col">

      {uploading ?
        <div className="flex bg-slate-100/0 h-12 w-full rounded-xl text-center text-white content-center justify-center items-center border-2 border-dashed border-sky-500 ">
          <PulseLoader color="white" />

        </div> :
        <FileUploader handleChange={handleChange} onDrop={onDrop} name="files" multiple={true}>
          <div className="flex bg-slate-100/0 h-12 w-full rounded-xl text-center text-white content-center justify-center items-center border-2 border-dashed border-sky-500 ">
            {uploadIcon} Click to upload

          </div>

        </FileUploader>
      }

    </div>
  );
}

export default DragDrop;