export default function Footer() {
	return (
		<div className='flex w-full flex-col py-4 bg-banner-navy items-center'>
			<div className='w-full xl:w-1/2'>
				<div className='flex flex-col divide-y divide-white/[0.5] pl-4 space-y-1'>
					<a href='https://pimpamassc.eq.edu.au/our-school/contact-us' className='text-white font-bold'>Contact Us</a>
					<div>
						<div className='flex flex-row'>
							<p className='font-bold pr-1 text-white'>
								A
							</p>
							<p className='text-white'>
								Dixon Drive, Pimpama, QLD, 4209
							</p>
						</div>
						<div className='flex flex-row'>
							<p className='font-bold pr-1 text-white'>
								E
							</p>
							<a href='mailto:admin@pimpamassc.eq.edu.au' className='text-white'>
								admin@pimpamassc.eq.edu.au
							</a>
						</div>
						<div className='flex flex-row'>
							<p className='font-bold pr-1 text-white'>
								P
							</p>
							<p className='text-white'>
								07 5540 9333
							</p>
						</div>
						<div className='flex flex-row'>
							<p className='font-bold pr-1 text-white'>
								F
							</p>
							<p className='text-white'>
								07 5540 7270
							</p>
						</div>

					</div>


				</div>

			</div>


		</div>
	)
}